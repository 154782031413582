<template>

    <div v-if="blurb.name" class="parentContainer">

        <div v-if="blurb.ties" :class="classVariable">
            <div v-if="Object.entries(blurb.ties).length == 0">
                <h2>
                    We haven't found any collaborations at this university.
                </h2>
                <h2>
                    Do you know of one? <a href="mailto:academiccomplicity@proton.me">Get in touch!</a>
                </h2>
            </div>

            <div v-else>
                <div class="uniCount">
                    <h2>
                        <span id="university_name">{{ blurb.name }}</span> <br>
                        <span v-if="blurb.name == 'Vlaams Instituut voor Biotechnologie'">is an institution with staff
                            at UGent, KULeuven,<br>UAntwerpen, VUB, IMEC and UHasselt, and <br></span>
                        <span v-if="blurb.name == 'Interuniversity Microelectronics Centre'">is an institution with
                            research groups affiliated with <br> UGent, KULeuven, UAntwerpen, VUB, and UHasselt, and
                            <br></span>
                        collaborates on <span class="countValue"> {{ this.numberActive[1] }} </span> projects <br>
                        with <span class="countValue">{{ this.numberActive[0] }} </span> Israeli partners or
                        companies arming Israel.
                    </h2>
                </div>
            </div>

            <div v-for="(instituteTies, key) in blurb.ties" :key="key" class="tieItem">
                <h3>{{ key }}</h3>
                <div v-if="partners.hasOwnProperty(key)">
                    <p class="partnerDescription">
                        <span v-html="partners[key].length < 250
                            ? partners[key]
                            : partners[key].substring(0, 250) + '... '
                            "></span><span v-if="partners[key].length > 249"
                            style="text-decoration: underline; cursor: pointer" @click="() => TogglePopup(key)">Read
                            More</span>
                    </p>
                </div>
                <PopUp v-if="this.partnerTriggerValue[key]" :TogglePopup="() => TogglePopup(key)">
                    <h2>{{ key }}</h2>
                    <p v-html="partners[key]"></p>
                </PopUp>

                <div v-for="tie in instituteTies" :key="tie.description" class="tieText">
                    <p v-if="this.isOngoing(tie)" v-html="tie.description"></p>
                    <p v-else v-html="tie.description" class="inactive"></p>
                    <ul v-if="tie.URLs">
                        <li v-for="URL in tie.URLs" :key="URL">
                            <a :href="URL" target="_blank">Source</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div :class="classVariable">
            <div v-if="blurb.groups.length > 0">
                <h3>Groups campaigning for Palestine:</h3>
                <div v-for="item in blurb.groups" :key="item.name" class="tieItem">
                    <img :src="require('../assets/logo_thumbs/' + item.img)" class="icon" />
                    <div class="tieText">
                        <a :href="item.link" target="_blank">{{ item.name }}</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="moveToMiddle disclaimer">
            We try to keep this overview up to date and accurate. The EC Cordis data was retrieved 24.7.24 and the other
            data is accurate to our best knowledge as of 08.11.24. If you have
            any new information or corrections, please
            <a href="mailto:academiccomplicity@proton.me">contact us</a>.
        </div>
    </div>
    <h3 v-else>Select an institution to find out more</h3>
</template>

<script>
import partners from "../assets/partners.json";
import PopUp from "./PopUp.vue";
//import { ref } from 'vue';

export default {
    name: "ShortSummary",
    components: {
        PopUp,
    },
    props: {
        blurb: {
            type: Object,
            default() {
                return {
                    shortForm: "nuin",
                    uni: "",
                };
            },
        },
    },
    data() {
        return {
            width: document.documentElement.clientWidth,
            partners: partners,
            partnerTriggerValue: [],
        };
    },
    mounted() {
        window.addEventListener("resize", this.changeClass);
        for (var key in partners) {
            this.partnerTriggerValue[key] = false;
        }
    },
    computed: {
        classVariable() {
            if (this.width < 610) {
                return "smallBlurb";
            } else {
                return "largeBlurb";
            }
        },
        legend() {
            if (this.width < 610) {
                return "legendSmall";
            } else {
                return "legendLarge";
            }
        },
        numberActive() {


            const today = new Date()
            // If no end_date is specified, the project is considered as current
            const isOngoing = (project) => project?.end_date ? new Date(project.end_date) > today : true

            let tmp =  this.blurb.ties;
            let active_projects = 0
            let active_partners = 0
            let partner_active = 0
            Object.keys(tmp).forEach(function (key) {
              partner_active = 0
              for (let i = 0; i < (tmp[key].length); i++) {
                if (isOngoing(tmp[key][i])) {
                  active_projects++
                  partner_active = 1
                }
              }
              if (partner_active) {
                active_partners++
              }
            });

            //More functional approach - same result

            // let active_projects = Object.values(tmp).reduce((total, partner) => total + partner.filter(project => isOngoing(project)).length , 0)
            // let active_partners =  Object.values(tmp).reduce((total, partner) => total + (partner.some(project => isOngoing(project)) ? 1 : 0), 0)

            return [active_partners, active_projects]
        }
    },
    methods: {
        changeClass() {
            this.width = document.documentElement.clientWidth;
        },
        TogglePopup(key) {
            this.partnerTriggerValue[key] = !this.partnerTriggerValue[key];
        },
        isOngoing(project) {
          const today = new Date()
          // If no end_date is specified, the project is considered as current
          return project?.end_date ? new Date(project.end_date) > today : true
        }
    },
    setup() {
        /*	const popupTriggers = ref({
        for (var key in partners) {
          key: false,
        }
      });

      const TogglePopup = (key) => {
        popupTriggers.value[key] = !popupTriggers.value[key]
      }

      return {
        PopUp,
        popupTriggers,
        TogglePopup
      } */
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.blurb {
    margin: 20px;
    padding: 0;
    align-content: center;
    text-align: center;
}

.smallBlurb {
    width: 100%;
    flex-grow: 1;
}

.largeBlurb {
    width: 60%;
    flex-grow: 1;
}

.icon {
    width: 56px;
    height: 56px;
    margin-left: 7px;
    margin-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: auto;
    margin-bottom: auto;
}

.iconMiddle {
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.iconLegend {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.iconsTogether {
    width: 140px;
}

.subsection {
    text-align: left;
    display: flex;
}

h3 {
    margin: 8px 0px;
    margin-top: 12px;
    margin-bottom: 6px;
    padding: 0px;
    text-align: left;
}

p {
    margin-top: 0px;
    margin-bottom: 5px;
}

.subsectionLegend {
    text-align: left;
    /*display: flex;*/
    border: grey 1px solid;
    padding: 10px;
}

.legendItem {
    display: flex;
}

.subsectiondescription {
    padding-left: 10px;
    align-items: center;
}

.parentContainer {
    display: flex;
    flex-flow: row wrap;
}

.legendLarge {
    width: 36%;

    padding: 2%;
    float: left;
    left: 20vw;
    /*padding: 10px
  /*flex-grow: 4*/
}

.legendSmall {
    padding: 2%;
    /*padding: 10px
  /*flex-grow: 4*/
    margin-left: auto;
    margin-right: auto;
}
</style>
